import React from 'react';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { Section } from '~/styles/Section.styled';
import { Wrapper } from '~/styles/Wrapper.styled';
import { StyledFaqPage } from './faq.styled';
import { FaqBlock } from '~/components/Generic/FaqBlock/FaqBlock';
import { SocialContact } from '~/components/Generic/SocialContact/SocialContact';

const Faq: React.FC<FaqPageProps> = ({ data }) => {
  const { page, faq } = data;

  const regularSections = page.sections.filter(
    section => !section.slug.endsWith('social-contact')
  );

  const socialContactSection = page.sections.find(section =>
    section.slug.endsWith('social-contact')
  );

  return (
    <Layout>
      <Metadata title={page.name} />

      <StyledFaqPage>
        <Section className="lead" gutter>
          <Wrapper width="normal">
            <article>
              <h1>{page.name}</h1>
              {regularSections.map(section => {
                if (section.slug === 'generic-social-contact') {
                }

                return (
                  <section
                    key={section.id}
                    dangerouslySetInnerHTML={{
                      __html: section.description?.childMarkdownRemark?.html,
                    }}
                  ></section>
                );
              })}
            </article>
          </Wrapper>
        </Section>
        <Section gutter>
          <Wrapper width="normal">
            {faq.topics.map((topic, tID) => {
              return (
                <div className="topic" key={`faq-topic-${tID}`}>
                  <h3>{topic.name}</h3>

                  <FaqBlock questions={topic.questions} />
                </div>
              );
            })}
          </Wrapper>
        </Section>

        {socialContactSection && (
          <Section gutter>
            <Wrapper width="wide" bg="gray">
              <SocialContact section={socialContactSection} />
            </Wrapper>
          </Section>
        )}
      </StyledFaqPage>
    </Layout>
  );
};

export default Faq;

export const query = graphql`
  query FaqPage($locale: String) {
    page: contentfulPage(slug: { eq: "faq" }, node_locale: { eq: $locale }) {
      name
      sections {
        ...ContentSectionFragment
      }
    }
    faq: contentfulFaq(slug: { eq: "faq" }, node_locale: { eq: $locale }) {
      ...ContentFaqFragment
    }
  }
`;

export interface FaqQuestion {
  id: string;
  question: {
    question: string;
  };
  answer: MarkdownData;
}

interface FaqTopic {
  id: string;
  name: string;
  questions: FaqQuestion[];
}

interface Faq {
  topics: FaqTopic[];
}

interface FaqPageProps {
  data: {
    page: {
      name: string;
      sections: {
        id: string;
        slug: string;
        title: string;
        description: MarkdownData;
      }[];
    };
    faq: Faq;
  };
}
