import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';
import { StyledAccordionItem } from '~/components/Accordion/Accordion.styled';

export const StyledFaqPage = styled.div`
  a {
    color: ${({ theme }) => theme.colors.green};
  }
  & h1 {
    font-size: ${pxtorem(40)};
    font-weight: 300;
    line-height: 1;
    letter-spacing: -0.01em;
    margin: 0 0 0.5em;

    @media (min-width: 768px) {
      font-size: ${pxtorem(60)};
    }
  }

  & .lead {
    & p {
      font-size: ${pxtorem(12)};
      line-height: 1.2;
      margin: 0 0 1em;
    }

    @media (min-width: 768px) {
      & p {
        font-size: ${pxtorem(16)};
      }
    }
  }

  & .topic {
    margin: 25px 0 50px;

    & h3 {
      font-size: ${pxtorem(16)};
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      margin: 0 0 1em;
    }

    @media (min-width: 768px) {
      margin: 50px 0 100px;
    }
  }

  @media (max-width: 767px) {
    ${StyledAccordionItem} {
      & .accordionItem {
        &__trigger,
        &__content {
          font-size: ${pxtorem(12)};
        }
      }
    }
  }
`;
